var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex mobile:flex-col items-center rounded-md py-2 bg-white mb-2 worker-time",class:[
    ['R0', 'R1', 'R2'].includes(_vm.userRole) ? 'grid-cols-10' : 'grid-cols-9'
  ]},[_c('div',{staticClass:"flex w-2/12 mobile:w-full justify-start mobile:mb-2"},[_c('span',{staticClass:"hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"},[_vm._v("Projekt")]),(_vm.time.related_object)?_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":'/projects/' + _vm.time.related_object.uuid}},[_vm._v(_vm._s(_vm.time.related_object.name))]):_vm._e(),(_vm.time.related_object === null)?_c('span',[_vm._v(" - ")]):_vm._e()],1),_c('div',{staticClass:"flex w-2/12 justify-start mobile:w-full mobile:mb-2"},[_c('span',{staticClass:"hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"},[_vm._v("Ülesanne")]),_c('span',{class:Object.keys(_vm.time.task).length > 0 ? 'cursor-pointer' : '',on:{"click":function($event){Object.keys(_vm.time.task).length > 0 ? _vm.routeToTask(_vm.time) : ''}}},[_vm._v(_vm._s(Object.keys(_vm.time.task).length === 0 ? "-" : _vm.time.task.title))])]),_c('div',{staticClass:"flex availability-date-selector pr-2 mobile:pr-0 w-2/12 mobile:w-full items-center",class:_vm.editMode ? 'mobile:mb-2' : 'justify-start'},[_c('span',{staticClass:"hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"},[_vm._v("Kuupäev ")]),(!_vm.editMode)?_c('span',[_vm._v(_vm._s(_vm.parseStartDate(_vm.time.stopper_start).format("DD.MM.YYYY")))]):_vm._e(),(_vm.editMode)?_c('date-picker',{staticClass:"taskmodal-datepicker mobile:w-7/12",attrs:{"monday-first":true,"format":_vm.formatDate(_vm.startDate),"language":_vm.ee},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}):_vm._e()],1),_c('div',{staticClass:"flex w-2/12 mobile:w-full items-center relative",class:_vm.editMode ? 'mobile:mb-2' : 'justify-start'},[_c('span',{staticClass:"hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"},[_vm._v("Algus ")]),(!_vm.editMode)?_c('span',[_vm._v(" "+_vm._s(_vm.parseStartTime(_vm.time.stopper_start)))]):_vm._e(),(
        !_vm.editMode &&
          _vm.time.editions.filter(function (x) { return x.field_name === 'stopper_start'; }).length >
            0
      )?_c('span',{staticClass:"typcn typcn-info text-danger cursor-pointer ml-2 pb-1",on:{"mouseover":function($event){_vm.startTimeHover = true},"mouseleave":function($event){_vm.startTimeHover = false},"click":function($event){_vm.startTimeHover = !_vm.startTimeHover}}}):_vm._e(),(_vm.editMode)?_c('time-selector',{staticClass:"flex flex-row w-full justify-start mobile:w-7/12",class:_vm.startTime > _vm.endTime ? 'wrong-time' : '',attrs:{"value":_vm.startTime,"full-minutes":true,"is-white":false},on:{"input":_vm.handleStartTimeChange}}):_vm._e(),(
        _vm.startTimeHover &&
          !_vm.editMode &&
          _vm.time.editions.filter(function (x) { return x.field_name === 'stopper_start'; }).length >
            0
      )?_c('div',{staticClass:"flex absolute w-128 flex-col z-10 rounded-gwdhalf bg-offwhite time-hover"},_vm._l((_vm.time.editions.filter(
          function (x) { return x.field_name === 'stopper_start'; }
        )),function(edition){return _c('div',{key:edition.initial_value,staticClass:"p-2 flex flex-row mb-1 justify-between mobile:my-2"},[_c('span',{staticClass:"text-sm mr-1"},[_vm._v("Muutja: "+_vm._s(edition.editor))]),_c('span',{staticClass:"text-sm ml-1"},[_vm._v("Eelmine: "+_vm._s(_vm.formatTime(edition.initial_value)))])])}),0):_vm._e()],1),_c('div',{staticClass:"flex w-2/12 justify-start mobile:w-full relative items-center",class:_vm.editMode ? 'mobile:mb-2' : 'justify-start'},[_c('span',{staticClass:"hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"},[_vm._v("Lõpp ")]),(_vm.time.stopper_end === null)?_c('span',[_vm._v("Töötab")]):_vm._e(),(!_vm.editMode && _vm.time.stopper_end !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.parseStartTime(_vm.time.stopper_end)))]):_vm._e(),(
        !_vm.editMode &&
          _vm.time.editions.filter(function (x) { return x.field_name === 'stopper_end'; }).length > 0
      )?_c('span',{staticClass:"typcn typcn-info text-danger cursor-pointer ml-2 pb-1",on:{"mouseover":function($event){_vm.endTimeHover = true},"mouseleave":function($event){_vm.endTimeHover = false},"click":function($event){_vm.endTimeHover = !_vm.endTimeHover}}}):_vm._e(),(_vm.editMode && _vm.time.stopper_end !== null)?_c('time-selector',{staticClass:"flex flex-row w-full justify-start mobile:w-7/12",class:_vm.startTime > _vm.endTime ? 'wrong-time' : '',attrs:{"full-minutes":true,"value":_vm.endTime,"is-white":false},on:{"input":_vm.handleEndTimeChange}}):_vm._e(),(
        _vm.endTimeHover &&
          !_vm.editMode &&
          _vm.time.editions.filter(function (x) { return x.field_name === 'stopper_end'; }).length > 0
      )?_c('div',{staticClass:"flex absolute w-128 flex-col z-10 rounded-gwdhalf bg-offwhite time-hover"},_vm._l((_vm.time.editions.filter(
          function (x) { return x.field_name === 'stopper_end'; }
        )),function(edition){return _c('div',{key:edition.initial_value,staticClass:"p-2 flex flex-row mb-1 justify-between mobile:my-2"},[_c('span',{staticClass:"text-sm mr-1"},[_vm._v("Muutja: "+_vm._s(edition.editor))]),_c('span',{staticClass:"text-sm ml-1"},[_vm._v("Eelmine: "+_vm._s(_vm.formatTime(edition.initial_value)))])])}),0):_vm._e()],1),_c('div',{staticClass:"flex w-1/12 justify-start mobile:w-full mobile:mb-2"},[_c('span',{staticClass:"hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"},[_vm._v("Aeg ")]),_c('span',{staticClass:"mobile:w-7/12"},[_vm._v(_vm._s(_vm.spentTime(_vm.time)))])]),_c('div',{staticClass:"w-1/12 justify-start hidden mobile:w-full"},[_c('span',[_vm._v(_vm._s(_vm.paymentForTime(_vm.time))+"€")])]),(['R0', 'R1', 'R2'].includes(_vm.userRole))?_c('div',{staticClass:"flex justify-between mobile:w-full w-1/12"},[(!_vm.editMode)?_c('button',{staticClass:"new-button-pending"},[_c('span',{staticClass:"typcn-edit typcn icon",on:{"click":function($event){_vm.editMode = !_vm.editMode}}})]):_vm._e(),(_vm.editMode)?_c('button',{staticClass:"new-button-green"},[_c('span',{staticClass:"typcn-tick typcn icon",on:{"click":_vm.saveTime}})]):_vm._e(),_c('button',{staticClass:"new-button-danger",on:{"click":function($event){_vm.isDeleteModalActive = true}}},[_vm._m(0)])]):_vm._e(),(_vm.isDeleteModalActive)?_c('delete-confirm-modal',{on:{"confirmDelete":function($event){return _vm.deleteTime()},"closeModal":function($event){_vm.isDeleteModalActive = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{staticClass:"h-5 filter-to-white",attrs:{"src":"/bc21/trash.svg"}})])}]

export { render, staticRenderFns }