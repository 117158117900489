<template>
  <div
    class="w-full flex mobile:flex-col items-center rounded-md py-2 bg-white mb-2 worker-time"
    :class="[
      ['R0', 'R1', 'R2'].includes(userRole) ? 'grid-cols-10' : 'grid-cols-9'
    ]"
  >
    <div class="flex w-2/12 mobile:w-full justify-start mobile:mb-2">
      <span class="hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"
        >Projekt</span
      >
      <router-link
        class="cursor-pointer"
        v-if="time.related_object"
        :to="'/projects/' + time.related_object.uuid"
        >{{ time.related_object.name }}</router-link
      >
      <span v-if="time.related_object === null"> - </span>
    </div>
    <div class="flex w-2/12 justify-start mobile:w-full mobile:mb-2">
      <span class="hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"
        >Ülesanne</span
      >
      <span
        :class="Object.keys(time.task).length > 0 ? 'cursor-pointer' : ''"
        @click="Object.keys(time.task).length > 0 ? routeToTask(time) : ''"
        >{{ Object.keys(time.task).length === 0 ? "-" : time.task.title }}</span
      >
    </div>
    <div
      class="flex availability-date-selector pr-2 mobile:pr-0 w-2/12 mobile:w-full items-center"
      :class="editMode ? 'mobile:mb-2' : 'justify-start'"
    >
      <span class="hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"
        >Kuupäev
      </span>
      <span v-if="!editMode">{{
        parseStartDate(time.stopper_start).format("DD.MM.YYYY")
      }}</span>
      <date-picker
        v-if="editMode"
        class="taskmodal-datepicker mobile:w-7/12"
        :monday-first="true"
        :format="formatDate(startDate)"
        :language="ee"
        v-model="startDate"
      ></date-picker>
    </div>
    <div
      class="flex w-2/12 mobile:w-full items-center relative"
      :class="editMode ? 'mobile:mb-2' : 'justify-start'"
    >
      <span class="hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"
        >Algus
      </span>
      <span v-if="!editMode"> {{ parseStartTime(time.stopper_start) }}</span>
      <span
        v-if="
          !editMode &&
            time.editions.filter(x => x.field_name === 'stopper_start').length >
              0
        "
        class="typcn typcn-info text-danger cursor-pointer ml-2 pb-1"
        @mouseover="startTimeHover = true"
        @mouseleave="startTimeHover = false"
        @click="startTimeHover = !startTimeHover"
      ></span>
      <time-selector
        v-if="editMode"
        class="flex flex-row w-full justify-start mobile:w-7/12"
        :value="startTime"
        :full-minutes="true"
        @input="handleStartTimeChange"
        :is-white="false"
        :class="startTime > endTime ? 'wrong-time' : ''"
      ></time-selector>
      <div
        v-if="
          startTimeHover &&
            !editMode &&
            time.editions.filter(x => x.field_name === 'stopper_start').length >
              0
        "
        class="flex absolute w-128 flex-col z-10 rounded-gwdhalf bg-offwhite time-hover"
      >
        <div
          v-for="edition in time.editions.filter(
            x => x.field_name === 'stopper_start'
          )"
          :key="edition.initial_value"
          class="p-2 flex flex-row mb-1 justify-between mobile:my-2"
        >
          <span class="text-sm mr-1">Muutja: {{ edition.editor }}</span>
          <span class="text-sm ml-1"
            >Eelmine: {{ formatTime(edition.initial_value) }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="flex w-2/12 justify-start mobile:w-full relative items-center"
      :class="editMode ? 'mobile:mb-2' : 'justify-start'"
    >
      <span class="hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"
        >Lõpp
      </span>
      <span v-if="time.stopper_end === null">Töötab</span>
      <span v-if="!editMode && time.stopper_end !== null">
        {{ parseStartTime(time.stopper_end) }}</span
      >
      <span
        v-if="
          !editMode &&
            time.editions.filter(x => x.field_name === 'stopper_end').length > 0
        "
        class="typcn typcn-info text-danger cursor-pointer ml-2 pb-1"
        @mouseover="endTimeHover = true"
        @mouseleave="endTimeHover = false"
        @click="endTimeHover = !endTimeHover"
      ></span>
      <time-selector
        v-if="editMode && time.stopper_end !== null"
        :full-minutes="true"
        class="flex flex-row w-full justify-start mobile:w-7/12"
        :value="endTime"
        @input="handleEndTimeChange"
        :is-white="false"
        :class="startTime > endTime ? 'wrong-time' : ''"
      ></time-selector>
      <div
        v-if="
          endTimeHover &&
            !editMode &&
            time.editions.filter(x => x.field_name === 'stopper_end').length > 0
        "
        class="flex absolute w-128 flex-col z-10 rounded-gwdhalf bg-offwhite time-hover"
      >
        <div
          v-for="edition in time.editions.filter(
            x => x.field_name === 'stopper_end'
          )"
          :key="edition.initial_value"
          class="p-2 flex flex-row mb-1 justify-between mobile:my-2"
        >
          <span class="text-sm mr-1">Muutja: {{ edition.editor }}</span>
          <span class="text-sm ml-1"
            >Eelmine: {{ formatTime(edition.initial_value) }}</span
          >
        </div>
      </div>
    </div>
    <div class="flex w-1/12 justify-start mobile:w-full mobile:mb-2">
      <span class="hidden mobile:flex mr-2 mobile:mr-0 mobile:w-5/12"
        >Aeg
      </span>
      <span class="mobile:w-7/12">{{ spentTime(time) }}</span>
    </div>
    <div class="w-1/12 justify-start hidden mobile:w-full">
      <span>{{ paymentForTime(time) }}€</span>
    </div>

    <div
      v-if="['R0', 'R1', 'R2'].includes(userRole)"
      class="flex justify-between mobile:w-full w-1/12"
    >
      <button class="new-button-pending" v-if="!editMode">
        <span
          class="typcn-edit typcn icon"
          @click="editMode = !editMode"
        ></span>
      </button>
      <button class="new-button-green" v-if="editMode">
        <span class="typcn-tick typcn icon" @click="saveTime"></span>
      </button>
      <button @click="isDeleteModalActive = true" class="new-button-danger">
        <span class="icon"
          ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
        /></span>
      </button>
    </div>
    <delete-confirm-modal
      v-if="isDeleteModalActive"
      @confirmDelete="deleteTime()"
      @closeModal="isDeleteModalActive = false"
    ></delete-confirm-modal>
  </div>
</template>

<script>
import moment from "moment";
import TimeSelector from "@/components/reusable/TimeSelector";
import { ee } from "vuejs-datepicker/dist/locale";
import DatePicker from "vuejs-datepicker";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal";

export default {
  name: "WorkerTime",
  components: { DeleteConfirmModal, TimeSelector, DatePicker },
  props: {
    time: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editMode: false,
      startTime: null,
      endTime: null,
      ee: ee,
      startDate: null,
      startTimeHover: false,
      endTimeHover: false,
      isDeleteModalActive: false,
      moment: moment
    };
  },
  methods: {
    routeToTask(time) {
      if (Object.keys(time.task).length > 0) {
        this.$emit("routeToTask", time.task.id);
      }
    },
    saveTime() {
      if (this.startTime > this.endTime) {
        return false;
      }
      if (
        this.startTime === this.parseStartTime(this.time.stopper_start) &&
        this.endTime === this.parseStartTime(this.time.stopper_end) &&
        this.startDate === this.formatDateInput(this.time.stopper_start)
      ) {
        this.editMode = false;
      } else {
        this.$emit("update", {
          id: this.time.id,
          stopper_start: this.startTime,
          stopper_end: this.endTime,
          stopper_day: this.startDate
        });
      }
    },
    formatDateInput(date) {
      return moment(date);
    },
    parseStartDate(date) {
      return moment(date);
    },
    parseStartTime(date) {
      return moment(date).format("HH:mm");
    },
    spentTime(date) {
      if (date.stopper_end === null) {
        return "-";
      }
      return moment
        .utc(
          moment
            .duration(moment(date.stopper_end).diff(moment(date.stopper_start)))
            .asMilliseconds()
        )
        .format("HH:mm");
    },
    paymentForTime(date) {
      if (date.stopper_end === null) {
        return "- ";
      }
      return (
        Math.round(
          ((moment
            .duration(moment(date.stopper_end).diff(moment(date.stopper_start)))
            .asSeconds() /
            (60 * 60)) *
            5 +
            Number.EPSILON) *
            100
        ) / 100
      );
    },
    handleStartTimeChange(e) {
      this.startTime = e;
    },
    handleEndTimeChange(e) {
      this.endTime = e;
    },
    deleteTime() {
      this.$emit("delete", this.time.id);
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    }
  },
  mounted() {
    this.startTime = this.parseStartTime(this.time.stopper_start);
    this.endTime = this.parseStartTime(this.time.stopper_end);
    this.startDate = this.moment(this.time.stopper_start).toDate();
  }
};
</script>

<style lang="scss">
.item-container {
  @apply bg-offwhite flex rounded-md py-1 items-center;
}
.availability-date-selector {
  .vdp-datepicker {
    div {
      input {
        @apply shadow bg-offwhite;
      }
    }
  }
}
.wrong-time {
  select {
    @apply bg-danger;
  }
}
.time-hover {
  top: 100%;
}
</style>
