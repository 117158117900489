<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center py-1 mb-1">
      <span
        class="typcn typcn-user text-2xl text-green border-green border-2 rounded-full leading-none mr-2"
      ></span>
      <span v-if="!editMode">{{ getUserRole(worker.permissions) }}</span>
      <div v-if="editMode" class="w-full mb-2">
        <select
          v-model="editables.permissions"
          class="w-full bg-offwhite shadow focus:shadow-outline"
        >
          <option v-for="item in userRoles" :value="item.role" :key="item.key">
            {{ item.description }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex flex-row items-center mb-2 w-full">
      <div
        class="flex border-green border-2 rounded-full p-1 justify-center items-center"
      >
        <img src="/bc21/placeholder.svg" class="h-4 filter-to-green" />
      </div>
      <span v-if="!editMode" class="w-full pl-2">
        {{ worker.location ? worker.location.long_address : "-" }}</span
      >
      <div class="flex flex-col w-full pl-2" v-if="editMode">
        <location-inaddress
          v-if="!editables.location"
          :text="'Sisestage töötaja aadress'"
          :emit-standardized-object="true"
          @locationChanged="handleWorkerLocationChange"
          :location="editables.location ? editables.location.long_address : ''"
          class="mb-2"
        >
        </location-inaddress>
        <div
          v-if="editMode && editables.location"
          class="mb-2 bg-offwhite w-full rounded-xl py-1 px-2 flex items-center"
        >
          <span>{{ editables.location.long_address }}</span>
          <span
            class="bg-danger p-2 rounded-full ml-auto"
            @click="editables.location = null"
            ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
          /></span>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center py-1 mb-1">
      <span
        class="rounded-full text-green border-green border-2 leading-none p-1 mr-2"
        ><img src="/bc21/mail.svg" class="h-4 filter-to-green"
      /></span>
      <span v-if="!editMode"> {{ worker.email ? worker.email : "-" }}</span>
      <div v-if="editMode && worker.profile === null" class="w-full">
        <input
          type="text"
          v-model="emailField"
          class="bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full"
        />
      </div>
      <div v-if="editMode && worker.profile" class="w-full px-1 py-1">
        <span>{{ worker.email ? worker.email : "-" }}</span>
      </div>
    </div>
    <div class="flex flex-row items-center py-1 mb-1">
      <div
        class="flex border-green border-2 mr-2 rounded-full justify-center items-center p-1"
      >
        <span class="typcn typcn-phone h-4 leading-none text-green"></span>
      </div>
      <span v-if="!editMode">
        {{ worker.telephone_number ? worker.telephone_number : "-" }}</span
      >
      <div class="w-full flex mb-2" v-if="editMode">
        <select
          v-model="countryCode"
          class="mobile:w-3/12 medium:w-3/12 w-2/12 mr-2"
        >
          <option value="+372"><img src="" />+372</option>
        </select>
        <input
          class="mobile:w-9/12 medium:w-9/12 w-10/12 add-project-input"
          placeholder="Telefoninumber"
          v-model="phoneNumberInput"
          type="tel"
          pattern="[0-9]*"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LocationInaddress from "@/components/reusable/LocationInaddress";
import { email } from "vuelidate/lib/validators";
export default {
  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    },
    editMode: Boolean
  },
  data() {
    return {
      phoneNumberInput: "",
      countryCode: "+372",
      emailField: "",
      editables: {
        location: null,
        worker_email: "",
        id_code: "",
        iban_account_name: "",
        iban_code: "",
        permissions: "",
        telephone_number: ""
      },
      userRole: this.$store.state.companyData.activeCompany.worker_permission
    };
  },
  components: { LocationInaddress },
  mounted() {
    this.initEditables();
  },
  methods: {
    handleWorkerLocationChange(e) {
      this.editables.location = e;
    },
    initEditables() {
      this.editables.id_code = this.worker.id_code;
      this.editables.iban_account_name = this.worker.iban_account_name;
      this.editables.iban_code = this.worker.iban_code;
      this.editables.location = this.worker.location;
      this.editables.permissions = this.worker.permissions;
      if (
        this.worker.telephone_number &&
        this.worker.telephone_number.length > 0
      ) {
        this.phoneNumberInput = this.worker.telephone_number.substring(4);
      }
      this.editables.worker_email = this.worker.email;
      this.emailField = this.worker.email;
    }
  },
  watch: {
    editables: {
      deep: true,
      handler() {
        this.$emit("workerUpdated", this.editables);
      }
    },
    phoneNumberInput() {
      this.editables.telephone_number =
        this.countryCode + this.phoneNumberInput;
    },
    emailField() {
      this.editables.worker_email = this.emailField.toLowerCase();
    }
  },
  validators() {
    if (this.emailField.length > 0) {
      return {
        editables: {
          worker_email: {
            email
          }
        }
      };
    }
  }
};
</script>
