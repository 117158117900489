<template>
  <div class="flex flex-col shadow bg-white" v-if="workerLoaded">
    <div v-if="worker !== null" class="flex flex-col rounded-gwdhalf">
      <div class="flex flex-row justify-between">
        <div
          class="flex flex-row w-10/12 mobile:w-full justify-start mobile:justify-between mb-4"
        >
          <button
            class="new-button-danger mr-3"
            @click="closeDetailView(false)"
            v-if="!editMode && ['R0', 'R1', 'R2'].includes(userRole)"
          >
            <span class="typcn-chevron-left typcn icon"></span>
            <span class="label">Tagasi</span>
          </button>
          <button
            class=""
            @click="
              $store.state.companyData.activeCompany.subscription_permissions
                .client_portal
                ? toggleEditMode()
                : $store.dispatch('userData/openSubWarning')
            "
            v-if="['R0', 'R1', 'R2'].includes(userRole) && !editMode"
            :class="editMode ? 'new-button-danger' : 'new-button-pending'"
          >
            <span class="typcn typcn-edit icon"></span>
            <span class="label" v-if="!editMode">Muuda</span>
          </button>
        </div>
        <button
          v-if="['R0', 'R1', 'R2'].includes(userRole) && editMode"
          class="new-button-danger"
          @click="isConfirmDeleteModalActive = true"
        >
          <span class="icon"
            ><img src="/bc21/trash.svg" class="h-4 filter-to-white"
          /></span>
          <span class="label">Kustuta töötaja</span>
        </button>
      </div>
      <div class="flex flex-row flex-wrap">
        <div class="flex flex-col w-full">
          <div class="flex">
            <h2>{{ worker.profile_name }}</h2>
          </div>
          <div class="flex flex-row mobile:flex-wrap">
            <div class="flex flex-col justify-center pr-2 w-1/3 mobile:w-full">
              <h3>Kontaktinfo</h3>
              <contact-info-card
                :edit-mode="editMode"
                :worker="worker"
                @workerUpdated="handleWorkerUpdate"
              ></contact-info-card>
            </div>
            <div
              class="flex flex-col w-1/3 mobile:w-full px-2"
              v-if="
                ['R0', 'R1', 'R2'].includes(
                  $store.state.companyData.activeCompany.worker_permission
                )
              "
            >
              <h3>Isiklikud andmed</h3>
              <div class="flex flex-col items-start py-1">
                <h4 class="mr-2">Arvelduskonto nimi</h4>
                <span v-if="!editMode">
                  {{
                    worker.iban_account_name ? worker.iban_account_name : "-"
                  }}</span
                >
                <div v-if="editMode" class="w-full">
                  <input
                    type="text"
                    v-model="editables.iban_account_name"
                    :class="
                      $v.$error &&
                      editables.iban_code !== null &&
                      editables.iban_code !== '' &&
                      (editables.iban_account_name === null ||
                        editables.iban_account_name === '' ||
                        (!$v.editables.iban_account_name.$error &&
                          !$v.editables.iban_account_name.required))
                        ? 'shadow-dangeroutline'
                        : ''
                    "
                    class="bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full"
                  />
                </div>
              </div>
              <div class="flex flex-col items-start py-1">
                <h4 class="mr-2">Arvelduskonto number</h4>
                <span v-if="!editMode">
                  {{ worker.iban_code ? worker.iban_code : "-" }}</span
                >
                <div v-if="editMode" class="w-full">
                  <input
                    type="text"
                    v-model="editables.iban_code"
                    class="bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full"
                    :class="
                      editables.iban_code.length > 0 &&
                      $v.editables.iban_code.$invalid
                        ? 'shadow-dangeroutline'
                        : ''
                    "
                  />
                </div>
              </div>
              <div class="flex flex-col items-start py-1">
                <h4 class="mr-2">Isikukood</h4>
                <span v-if="!editMode">
                  {{ worker.id_code ? worker.id_code : "-" }}</span
                >
                <div v-if="editMode" class="w-full">
                  <input
                    type="text"
                    v-model="editables.id_code"
                    class="bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full"
                  />
                </div>
              </div>
            </div>
            <div
              class="flex w-1/3 justify-start mobile:w-full mb-8 p-2"
              v-if="!editMode"
            >
              <div
                v-if="worker.profile !== null"
                class="flex flex-col justify-start items-start mobile:items-start w-full"
              >
                <div class="flex items-center w-full mb-2">
                  <h3 class="mr-2">Kasutaja ühendatud</h3>
                  <span
                    class="w-6 h-6 rounded-full bg-green typcn typcn-tick flex justify-center items-center text-white text-xl leading-none shadow"
                  >
                  </span>
                </div>
                <div
                  class="flex items-center bg-offwhite rounded-xl shadow mobile:w-full w-full"
                >
                  <div class="items-center justify-start flex w-3/12">
                    <div class="p-2">
                      <img :src="workerImage" class="h-12 w-12 rounded-full" />
                    </div>
                  </div>
                  <span class="w-9/12"> {{ worker.profile_name }}</span>
                </div>
              </div>
              <div
                v-if="
                  worker.profile === null &&
                    ['R0', 'R1', 'R2'].includes(
                      $store.state.companyData.activeCompany.worker_permission
                    )
                "
                class="flex flex-col items-center mobile:items-start"
              >
                <div v-if="worker.join_request_sent" class="flex flex-col">
                  <h3 class="mb-2">
                    Gewodo kasutaja loomise kutse saadetud!<br />
                  </h3>
                  <span class="mb-2" v-if="worker.join_request_email">
                    <span
                      >Kutse saadetud aadressile
                      <span class="font-medium">{{
                        worker.join_request_email
                      }}</span
                      >.</span
                    >
                  </span>
                  <span v-if="joinRequestResent" class="mb-2">
                    Liitumise kutse edukalt uuesti saadetud!
                  </span>
                  <div class="flex flex-row">
                    <button
                      class="alt-button-pending mr-2"
                      @click="reSendJoinRequest"
                    >
                      <span class="icon typcn typcn-plus"></span>
                      <span class="label">Saada uuesti</span>
                    </button>
                    <button
                      class="alt-button-danger ml-2"
                      @click="deleteJoinRequest"
                    >
                      <span class="icon typcn typcn-trash"></span>
                      <span class="label">Tühista liitumise kutse</span>
                    </button>
                  </div>
                </div>

                <div v-if="!worker.join_request_sent" class="flex flex-col">
                  <div class="flex items-center mb-2">
                    <h3 class="mr-3">Kasutaja ühendamata</h3>
                    <span class="w-4 h-4 bg-attention rounded-full"></span>
                  </div>
                  <span
                    class="mb-2"
                    v-if="
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                    "
                    >Saada kasutajale Gewodo konto ühendamise taotlus</span
                  >
                  <div
                    class="mb-2 flex flex-col"
                    v-if="
                      !$store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                    "
                  >
                    <span class="mb-2"
                      >Et töötajatele kontosid luua, ostke Gewodo Premium
                      pakett.</span
                    >
                    <router-link to="/subscriptions/add"
                      ><button class="new-button-green">
                        Uuenda pakett
                      </button></router-link
                    >
                  </div>
                  <div
                    class="flex flex-col items-start w-full"
                    v-if="
                      $store.state.companyData.activeCompany
                        .subscription_permissions.client_portal
                    "
                  >
                    <input
                      type="text"
                      class="add-project-input mb-2 w-full"
                      placeholder="Kasutaja meiliaadress"
                      v-model="joinEmail"
                    />
                    <button class="new-button-green" @click="sendJoinRequest">
                      <span class="icon">
                        <img
                          src="/bc21/mail.svg"
                          class="h-4 w-4 filter-to-white"
                      /></span>

                      <span class="label">Saada</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="editMode" class="py-4 flex flex-col">
            <div class="error-box flex-col mb-4" v-if="$v.$error">
              <span
                v-if="
                  !$v.editables.id_code.maxLength ||
                    !$v.editables.id_code.minLength
                "
                >Isikukood peab koosnema 11-st numbrist</span
              >
              <span v-if="!$v.editables.id_code.numeric"
                >Isikukood tohib ainult numbreid sisaldada</span
              >
              <span v-if="!$v.editables.id_code.validateIdCodeControlNumber"
                >Isikukoodi kontrollnumber ei klapi</span
              >
              <span
                v-if="
                  editables.iban_code !== null &&
                    editables.iban_code !== '' &&
                    !$v.editables.iban_code.ibanValidator
                "
                >Arvelduskonto number ei ole korrektne</span
              >
              <span
                v-if="
                  editables.iban_code !== null &&
                    editables.iban_code !== '' &&
                    $v.editables.iban_account_name.$error &&
                    !$v.editables.iban_account_name.required
                "
              >
                Palun sisestage arvelduskontoga seotud nimi
              </span>
            </div>
            <div class="flex">
              <button @click="saveChanges" class="new-button-green mr-3">
                <span class="icon typcn typcn-tick"></span>
                <span class="label">Salvesta</span>
              </button>
              <button
                v-if="['R0', 'R1', 'R2'].includes(userRole) && editMode"
                @click="editMode = false"
                class="new-button-danger"
              >
                <span class="typcn typcn-times icon"></span>
                <span class="label">Tühista</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-4 w-full rounded-xl"
      v-if="
        $store.state.companyData.activeCompany.subscription_permissions
          .client_portal
      "
    >
      <div class="tab-tray">
        <div
          class="rounded-l-xl tab-tray-item w-1/3"
          :class="subPage === 1 ? 'tab-tray-item-active' : ''"
          @click="subPage = 1"
        >
          <span class="tab-tray-icon">
            <img src="/icons/worker/schedule.svg" />
          </span>
          <span class="tab-tray-label">
            Graafik
          </span>
        </div>
        <div
          class="tab-tray-item w-1/3"
          :class="subPage === 2 ? 'tab-tray-item-active' : ''"
          @click="subPage = 2"
        >
          <span class="tab-tray-icon">
            <img src="/icons/worker/hours.svg" />
          </span>
          <span class="tab-tray-label">
            Töötunnid
          </span>
        </div>
        <div
          class="tab-tray-item rounded-r-xl w-1/3"
          :class="subPage === 3 ? 'tab-tray-item-active' : ''"
          @click="subPage = 3"
        >
          <span class="tab-tray-icon">
            <img src="/bc21/dokumendid.svg" />
          </span>
          <span class="tab-tray-label">
            Failid
          </span>
        </div>
      </div>

      <div class="bg-white rounded-b-gwdhalf">
        <worker-schedule v-if="subPage === 1" :worker="worker">
        </worker-schedule>
        <worker-times
          class="flex"
          :worker="worker"
          :company-uuid="companyUuid"
          :user-role="userRole"
          v-if="subPage === 2"
          @routeToTask="routeToTask"
        ></worker-times>
        <worker-files
          class="flex"
          v-if="subPage === 3"
          :worker="worker"
          :company-uuid="companyUuid"
          :user-role="userRole"
        ></worker-files>
      </div>
    </div>
    <delete-confirm-modal
      text="Olete kindel, et soovite valitud töötaja kustutada? Seda tegevust ei saa tagasi muuta"
      v-if="isConfirmDeleteModalActive"
      @closeModal="isConfirmDeleteModalActive = false"
      @confirmDelete="deleteWorker()"
    >
    </delete-confirm-modal>
  </div>
</template>

<script>
import WorkerTimes from "@/components/company/workers/WorkerTimes";
import WorkerSchedule from "@/components/company/workers/WorkerSchedule";
import RequestHandler from "@/assets/mixins/RequestHandler";
import {
  required,
  maxLength,
  numeric,
  minLength
} from "vuelidate/lib/validators";
import WorkerFiles from "@/components/company/workers/WorkerFiles";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal";
import ContactInfoCard from "./detail/ContactInfoCard.vue";

const ibanValidator = val => {
  if (val.length === 20) {
    let code = val.substring(4).concat(val.substring(0, 4));
    code = code.replaceAll(/E/g, "14");
    let n1 = parseInt(code.substring(0, 9)) % 97;
    let n2 = parseInt(n1.toString(10).concat(code.substring(9, 16))) % 97;
    let n3 = parseInt(n2.toString(10).concat(code.substring(16))) % 97;

    return n3 === 1;
  } else {
    return false;
  }
};

const validateIdCodeControlNumber = val => {
  let i;
  if (val === null || val === "") {
    return true;
  }
  let multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1],
    multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3],
    mod,
    total = 0;

  for (i = 0; i < 10; i++) {
    total += val.charAt(i) * multiplier1[i];
  }
  mod = total % 11;

  total = 0;
  if (10 === mod) {
    for (i = 0; i < 10; i++) {
      total += val.charAt(i) * multiplier2[i];
    }
    mod = total % 11;
    if (10 === mod) {
      mod = 0;
    }
  }
  return mod === parseInt(val.charAt(10));
};

export default {
  name: "CompanyWorkerDetailView",
  components: {
    DeleteConfirmModal,
    WorkerFiles,
    WorkerTimes,
    WorkerSchedule,
    ContactInfoCard
  },
  props: {
    workerId: {
      type: Number,
      default: null
    }
  },
  mixins: [RequestHandler],
  data() {
    return {
      companyUuid: this.$store.state.companyData.activeCompany.uuid,

      userRole: this.$store.state.companyData.activeCompany.worker_permission,
      worker: null,
      joinEmail: "",
      workerLoaded: false,
      subPage: 1,
      editMode: false,
      emailField: "",
      editables: {
        location: null,
        worker_email: "",
        id_code: "",
        iban_account_name: "",
        iban_code: "",
        permissions: "",
        telephone_number: ""
      },
      countryCode: "+372",

      isConfirmDeleteModalActive: false,
      phoneNumberInput: "",
      workerImageLoaded: false,
      workerImage: "/favicon.ico",
      joinRequestResent: false
    };
  },
  methods: {
    routeToTask(e) {
      this.subPage = 1;
      this.$store.dispatch("taskViewer/openTaskModal", { task: e });
    },
    handleWorkerLocationChange(e) {
      this.editables.location = e;
    },
    handleWorkerUpdate(e) {
      this.editables = e;
    },
    retrieveWorkerDetailView() {
      this.editMode = false;
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/workers/" +
          this.$route.params.id +
          "/",
        "get",
        true
      ).then(res => {
        this.worker = res.data;
        document.title = `${this.worker.profile_name} | Gewodo`;
        this.initEditables();
        this.workerLoaded = true;
        this.getWorkerProfileImage();
      });
    },
    initEditables() {
      this.editables.id_code = this.worker.id_code;
      this.editables.iban_account_name = this.worker.iban_account_name;
      this.editables.iban_code = this.worker.iban_code;
      this.editables.permissions = this.worker.permissions;
      if (
        this.worker.telephone_number &&
        this.worker.telephone_number.length > 0
      ) {
        this.phoneNumberInput = this.worker.telephone_number.substring(4);
      }
      this.editables.worker_email = this.worker.email;
    },
    closeDetailView() {
      this.$router.push("/workers");
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    reSendJoinRequest() {
      this.joinRequestResent = false;
      this.sendJoinRequest();
      this.joinRequestResent = true;
    },
    sendJoinRequest() {
      this.$store.state.loading = true;
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/workers/" +
          this.worker.id +
          "/request/join/",
        "post",
        true,
        { email: this.addEmailLowercased }
      ).then(res => {
        if (res.status === 201) {
          this.retrieveWorkerDetailView();
        } else if (res.status === 200) {
          this.retrieveWorkerDetailView();
        }
      });
      this.$store.state.loading = false;
    },
    deleteJoinRequest() {
      this.$store.state.loading = true;
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/workers/" +
          this.worker.id +
          "/request/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 201) {
          this.retrieveWorkerDetailView();
        } else if (res.status === 200) {
          this.retrieveWorkerDetailView();
        }
      });
      this.$store.state.loading = false;
    },
    saveChanges() {
      let data = {};

      data.iban_account_name = this.editables.iban_account_name;
      data.iban_code = this.editables.iban_code;
      data.id_code = this.editables.id_code;
      data.location =
        this.editables.location && this.editables.location.full_object
          ? this.editables.location.full_object
          : this.editables.location;
      data.permissions = this.editables.permissions;
      data.telephone_number = this.editables.telephone_number;
      data.worker_email = this.editables.worker_email;

      this.$v.$touch();
      if (!this.$v.$error) {
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/workers/" +
            this.worker.id +
            "/edit/",
          "patch",
          true,
          data
        ).then(res => {
          if (res.status === 200) {
            this.retrieveWorkerDetailView();
          }
        });
      }
    },
    deleteWorker() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/workers/" +
          this.worker.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          this.closeDetailView(true);
        }
      });
    },
    getWorkerProfileImage() {
      if (this.worker.profile) {
        if (Object.keys(this.worker.image).length > 0) {
          this.apiRequest(
            "files/public/" + this.worker.image.id + "/?type=ProfileImage",
            "get"
          ).then(
            res => {
              this.workerImage = res.data.file_url;

              this.workerImageLoaded = true;
            },
            err => {
              // todo handle properly
              if (err) {
                this.$store.state.loading = false;
                this.workerImage = "/favicon.ico";
                this.workerImageLoaded = true;
              }
            }
          );
        } else {
          this.workerImageLoaded = true;

          return "/favicon.ico";
        }
      } else {
        this.workerImageLoaded = true;

        return "/favicon.ico";
      }
    }
  },
  watch: {
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.editables.telephone_number =
          this.countryCode + this.phoneNumberInput.replace(test, "");
      }
    }
  },
  mounted() {
    this.retrieveWorkerDetailView();
  },
  computed: {
    profileImage() {
      if (Object.keys(this.worker.image).length === 0) {
        return "/favicon.ico";
      } else {
        return this.worker.image.image;
      }
    },
    sanitizedIban() {
      if (this.editables.iban_code === null) {
        return "";
      }
      return this.editables.iban_code.replaceAll(" ", "");
    },
    addEmailLowercased() {
      return this.joinEmail.toLowerCase();
    }
  },
  validations() {
    let validators = {
      editables: {
        id_code: {
          maxLength: maxLength(11),
          minLength: minLength(11),
          numeric,
          validateIdCodeControlNumber
        }
      }
    };

    if (
      this.editables.iban_code !== null &&
      this.editables.iban_code !== "" &&
      this.editables.iban_code.length > 0
    ) {
      validators.editables.iban_code = { required, ibanValidator };
      validators.editables.iban_account_name = { required };
    }

    return validators;
  }
};
</script>

<style lang="scss">
.worker-tray-item {
  @apply flex justify-center items-center cursor-pointer duration-100 w-1/3 bg-offwhite text-darkgrey py-1 px-4;
  .worker-tray-icon {
    @apply text-2xl leading-none h-7 w-7 flex justify-center items-center;
    img {
      @apply h-5 w-5;
    }
  }
  .worker-tray-label {
    @apply font-medium flex-auto pl-2 pr-3;
  }
  &:hover {
    @apply bg-darkgreen;
    .worker-tray-icon {
      @apply text-white;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(150deg)
          brightness(109%) contrast(103%);
      }
    }
    .worker-tray-label {
      @apply text-white;
    }
  }
  &.worker-tray-item-active {
    @apply bg-green;
    .worker-tray-icon {
      @apply text-white;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(150deg)
          brightness(109%) contrast(103%);
      }
    }
    .worker-tray-label {
      @apply text-white;
    }
  }
}
@media screen and (max-width: 992px) {
  .worker-tray-item {
    @apply w-full mb-2 mx-0;
  }
}
</style>
