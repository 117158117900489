<template>
  <div
    class="flex flex-col py-3 w-full mobile:items-center bg-white rounded-b-gwdhalf mobile:relative mt-3"
  >
    <div v-if="files.length === 0 && filesLoaded" class="mb-4">
      <span>Töötajal puuduvad dokumendid.</span>
    </div>
    <div
      class="flex flex-row w-full mb-4"
      v-if="files.length > 0 && filesLoaded"
    >
      <div class="flex flex-row flex-wrap -mx-1 mb-2 w-full">
        <project-file
          v-for="file in files"
          :key="file.id"
          :file="file"
          :user-role="userRole"
          :company="$store.state.companyData.activeCompany.uuid"
          @reloadFiles="loadWorkerFiles"
          @expandFile="openSidebar"
          @closeFile="closeSidebar"
          :current-expanded="expandedFile ? expandedFile.id : null"
        >
        </project-file>
      </div>
    </div>
    <div
      v-if="
        ['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
          $store.state.userData.user.id === worker.profile
      "
    >
      <button
        class="alt-button-green"
        v-if="!uploadWidgetActive"
        @click="uploadWidgetActive = true"
      >
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa</span>
      </button>
    </div>
    <file-modal
      v-if="$store.state.fileViewer.displayFileModal"
      :file="$store.state.fileViewer.fileToDisplay"
      :type="'file'"
      :company="$store.state.fileViewer.company"
      @fileDeleted="loadWorkerFiles"
    ></file-modal>
    <upload-file-modal
      v-if="uploadWidgetActive"
      @closeModal="uploadWidgetActive = false"
      @filesUpdated="loadWorkerFiles"
      :worker="worker"
    ></upload-file-modal>
  </div>
</template>

<script>
import ProjectFile from "@/components/project/ProjectFile";
import RequestHandler from "@/assets/mixins/RequestHandler";
import FileModal from "@/components/reusable/FileModal";
import UploadFileModal from "@/components/reusable/UploadFileModal";

export default {
  name: "WorkerFiles",
  components: { FileModal, ProjectFile, UploadFileModal },
  mixins: [RequestHandler],

  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      files: [],
      filesLoaded: false,
      showSidebar: false,
      expandedFile: null,
      uploadWidgetActive: false,
      uploadedFileSize: "0B",
      fileSizeWarning: false
    };
  },
  methods: {
    loadWorkerFiles() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/worker/" +
          this.$route.params.id +
          "/files/",
        "get",
        true
      ).then(res => {
        this.files = res.data;
        this.filesLoaded = true;
      });
    },
    addWorkerSpecificFiles() {
      this.$store.state.loading = true;
      if (this.$refs.companyFiles.files.length > 0) {
        const filesToUpload = new FormData();
        for (let i = 0; i < this.$refs.companyFiles.files.length; i++) {
          const file = this.$refs.companyFiles.files[i];
          const fileToAppend = new File([file], file.name, { type: file.type });
          filesToUpload.append("files", fileToAppend, file.name);
        }
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/worker/" +
            this.$route.params.id +
            "/files/add/",
          "post",
          true,
          filesToUpload
        )
          .then(res => {
            if (res.status === 201) {
              this.$refs.companyFiles.files = null;
              this.uploadWidgetActive = false;
              this.loadWorkerFiles();
            }
            this.$store.state.loading = false;
          })
          .catch(error => {
            this.$store.state.loading = false;
            const errObj = { error };
            if (errObj.error.response.status === 413) {
              window.scrollTo(0, 0);
            }
          });
      } else {
        this.$store.state.loading = false;
      }
    },
    openSidebar(e) {
      this.showSidebar = true;
      this.expandedFile = e;
    },
    closeSidebar() {
      this.showSidebar = false;
      this.expandedFile = null;
    },
    calculateUploadSize() {
      if (this.$refs.companyFiles.files.length > 0) {
        let num = 0;
        this.fileSizeWarning = false;
        for (let i = 0; i < this.$refs.companyFiles.files.length; i++) {
          const file = this.$refs.companyFiles.files[i];
          num += file.size;
        }
        if (num > this.remainingSize) {
          this.fileSizeWarning = true;
        }
        let exponent;
        let unit;
        let neg = num < 0;
        let units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        if (neg) {
          num = -num;
        }

        if (num < 1) {
          return (neg ? "-" : "") + num + " B";
        }

        exponent = Math.min(
          Math.floor(Math.log(num) / Math.log(1000)),
          units.length - 1
        );
        num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
        unit = units[exponent];

        this.uploadedFileSize = (neg ? "-" : "") + num + " " + unit;
      }
    }
  },
  mounted() {
    this.loadWorkerFiles();
  }
};
</script>
