<template>
  <div class="flex w-full px-2">
    <div class="flex flex-col w-full" v-if="weekInited">
      <week-selector
        :start-of-week="startOfWeek"
        @initWeek="initWeek"
      ></week-selector>
      <div class="grid grid-cols-7 mobile:grid-cols-1 w-full gap-x-1">
        <day
          :day="item"
          :has-day-view="true"
          v-for="item in weekArray"
          :key="item.index"
          @openAddTaskView="openAddTaskModal"
          @openDailyView="openDayView"
        ></day>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import Day from "./weekly/Day";
import WeekSelector from "./weekly/WeekSelector";
export default {
  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      currentWeek: null,
      startOfWeek: null,
      dbFormat: "YYYY-MM-DD",
      taskData: null,
      tasksInited: false,
      weekInited: false,
      weekArray: [],
      moment: moment,
      projects: []
    };
  },
  components: {
    Day,
    WeekSelector
  },
  mixins: [RequestHandler],
  methods: {
    initWeek(change) {
      if (change > 0) {
        this.startOfWeek = moment(this.startOfWeek)
          .add(change, "week")
          .startOf("week");
      } else if (change < 0) {
        this.startOfWeek = moment(this.startOfWeek)
          .subtract(1, "week")
          .startOf("week");
      } else {
        if (!this.startOfWeek) {
          this.startOfWeek = moment(this.currentDate).startOf("week");
        } else {
          this.startOfWeek = moment(this.startOfWeek).startOf("week");
        }
      }
      this.weekArray = [];
      for (let i = 0; i < 7; i++) {
        let dayObject = {};
        dayObject.index = i;
        dayObject.date = moment(this.startOfWeek).add(i, "day");
        dayObject.tasks = [];
        dayObject.hovered = false;
        this.weekArray.push(dayObject);
      }
      this.retrieveTasks(this.startOfWeek);

      this.weekInited = true;
    },
    retrieveTasks(startOfWeek) {
      for (let i = 0; i < this.weekArray.length; i++) {
        this.weekArray[i].tasks = [];
      }
      this.apiRequest(
        "calendar/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/tasks/" +
          this.worker.id +
          "?period=week&start_time=" +
          moment(startOfWeek).format(this.dbFormat),
        "get",
        true
      ).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          const weekDay = this.weekArray.find(item =>
            moment(item.date).isSame(res.data[i].start_time, "day")
          );
          if (weekDay) {
            weekDay.tasks.push(res.data[i]);
            if (
              res.data[i].related_object &&
              this.projects.find(
                item => item.project === res.data[i].related_object.object_uuid
              ) === undefined
            ) {
              this.projects.push({
                project: res.data[i].related_object.object_uuid,
                color: null
              });
            }
          }
        }
        this.sortTasks();
        this.tasksInited = true;
      });
    },
    openAddTaskModal(date) {
      this.$emit("openAddTaskModal", [date, this.worker]);
    },
    sortTasks() {
      for (let i = 0; i < this.weekArray.length; i++) {
        this.weekArray[i].tasks.sort((a, b) => {
          if (moment(a.start_time).unix() > moment(b.start_time).unix()) {
            return 1;
          } else if (
            moment(a.start_time).unix() < moment(b.start_time).unix()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    },
    openDayView(date) {
      this.$emit("openDailyView", date);
    }
  },
  computed: {
    canAddTasks() {
      if (
        ["R0", "R1", "R2", "R3"].includes(
          this.$store.state.companyData.activeCompany.worker_permission
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  mounted() {
    this.currentWeek = moment();
    this.initWeek();
  }
};
</script>
