<template>
  <div class="flex flex-col">
    <div class="flex py-2 justify-end px-1 mobile:hidden items-center">
      <a download @click.prevent="exportCalendar" target="_blank" class="mr-2">
        <button class="new-button-pending">
          <span class="icon"
            ><img src="/bc21/download.svg" class="w-5 h-5 filter-to-white"
          /></span>
          <span class="label">Ekspordi</span>
        </button>
      </a>
      <div class="flex rounded-full bg-offwhite shadow">
        <div
          class="bg-green rounded-l-full flex items-center justify-center px-2 "
        >
          <img src="/bc21/calendar.svg" class="w-4 h-4 filter-to-white" />
        </div>

        <button
          @click="view = 3"
          class="rounded-none shadow-none hover:shadow-none hover:bg-green focus:shadow-none py-2 px-2"
          :class="
            view === 3
              ? 'bg-green text-white'
              : 'bg-white text-green hover:text-white'
          "
        >
          Kuu
        </button>
        <button
          @click="view = 2"
          class="rounded-none shadow-none hover:shadow-none hover:bg-green focus:shadow-none py-2 px-2"
          :class="
            view === 2
              ? 'bg-green text-white'
              : 'bg-white text-green hover:text-white'
          "
        >
          Nädal
        </button>
        <button
          @click="view = 1"
          class="rounded-none rounded-r-full shadow-none hover:shadow-none hover:bg-green focus:shadow-none py-2 px-2"
          :class="
            view === 1
              ? 'bg-green text-white'
              : 'bg-white text-green hover:text-white'
          "
        >
          Päev
        </button>
      </div>
    </div>
    <div class="flex py-3">
      <daily-calendar-employee-manager
        v-if="view === 1"
        :worker="worker"
        :active-date="dailyDate"
        ref="daily"
      ></daily-calendar-employee-manager>
      <weekly-calendar-employee-manager
        :worker="worker"
        v-if="view === 2"
        ref="weekly"
        @openAddTaskModal="openAddTaskModal"
        @openDailyView="goToDayWeekly"
      ></weekly-calendar-employee-manager>
      <monthly-calendar-employee-manager
        :worker="worker"
        v-if="view === 3"
        ref="monthly"
        @openDailyView="goToDay"
      ></monthly-calendar-employee-manager>
    </div>
  </div>
</template>

<script>
import DailyCalendarEmployeeManager from "@/components/calendar/DailyCalendarEmployeeManager";
import WeeklyCalendarEmployeeManager from "@/components/calendar/WeeklyCalendarEmployeeManager";
import MonthlyCalendarEmployeeManager from "@/components/calendar/MonthlyCalendarEmployeeManager";
import RequestHandler from "@/assets/mixins/RequestHandler";
import moment from "moment";
import EventBus from "@/assets/mixins/EventBus";
export default {
  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    DailyCalendarEmployeeManager,
    WeeklyCalendarEmployeeManager,
    MonthlyCalendarEmployeeManager
  },
  data() {
    return {
      view: 2,
      dailyDate: null
    };
  },
  mounted() {
    EventBus.$on("updateTasks", () => {
      this.updateData();
    });
  },
  beforeDestroy() {
    EventBus.$off("updateTasks");
  },
  mixins: [RequestHandler],
  methods: {
    exportCalendar() {
      this.apiRequest(
        "tasks/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/export/google/?worker=" +
          this.$route.params.id,

        "get",
        true
      ).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "calendar.ics");
        document.body.appendChild(link);
        link.click();
      });
    },
    openAddTaskModal(e) {
      this.$store.dispatch("taskViewer/openAddTaskModal", {
        presetData: e,
        hasPresetData: true
      });
    },
    closeAddTaskModal() {
      this.$store.dispatch("taskViewer/closeAddTaskModal");
    },
    updateData() {
      if (this.view === 3) {
        this.$refs.monthly.initMonth();
      } else if (this.view === 2) {
        this.$refs.weekly.initWeek(0);
      } else if (this.view === 1) {
        this.$refs.daily.retrieveDayData();
      }
    },
    handleTaskEdit() {
      this.updateData();
    },
    goToDay(e) {
      this.dailyDate = moment(e.fullDate);
      this.view = 1;
    },
    goToDayWeekly(e) {
      this.dailyDate = e;
      this.view = 1;
    }
  }
};
</script>
