<template>
  <div
    v-if="timingsLoaded"
    class="flex flex-col p-3 bg-white rounded-b-gwdhalf w-full"
  >
    <div
      class="flex flex-row items-center rounded mobile:flex-col my-2 w-full justify-between"
    >
      <div
        class="flex flex-row mobile:flex-col mb-4 justify-start items-center mobile:w-full"
      >
        <div
          class="flex items-center mobile:items-start pr-2 mobile:pr-0 mobile:justify-start mobile:flex-col mobile:w-full mobile:mb-2"
        >
          <span class="mr-3 mobile:mb-1 flex">Algusaeg</span>
          <date-picker
            class="worker-times-date-selector flex mobile:w-full"
            :language="ee"
            :format="formatDate"
            :monday-first="true"
            v-model="startDate"
          >
          </date-picker>
        </div>
        <div
          class="flex flex-row items-center mobile:items-start pr-2 mobile:pr-0 mobile:justify-start mobile:flex-col mobile:mb-4 mobile:w-full"
        >
          <span class="mr-3 mobile:mb-1 flex">Lõpp</span>
          <date-picker
            class="worker-times-date-selector flex mobile:w-full"
            :language="ee"
            :format="formatDate"
            :monday-first="true"
            v-model="endDate"
          >
          </date-picker>
        </div>
        <button
          class="new-button-green"
          @click="showAddTimingForm = true"
          v-if="
            ['R0', 'R1', 'R2'].includes(userRole) &&
              $store.state.companyData.activeCompany.subscription_permissions
                .time_tracking
          "
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Lisa töötunde</span>
        </button>
      </div>
    </div>
    <div class="w-full flex items-center mobile:hidden">
      <h4 class="flex w-2/12 justify-start">Projekt</h4>
      <h4 class="flex w-2/12  justify-start">Ülesanne</h4>
      <h4 class="flex justify-start w-2/12 ">Kuupäev</h4>
      <h4 class="flex w-2/12  justify-start">Algus</h4>
      <h4 class="flex w-2/12  justify-start">Lõpp</h4>
      <h4 class="flex w-1/12 justify-start">Aeg</h4>
      <h4
        v-if="['R0', 'R1', 'R2'].includes(userRole)"
        class="flex w-1/12 justify-start"
      >
        Muuda
      </h4>
    </div>
    <div class="w-full">
      <worker-time
        v-for="time in timings"
        :key="time.id"
        :time="time"
        :user-role="userRole"
        @update="updateItem"
        @delete="deleteItem"
        @routeToTask="routeToTask"
      ></worker-time>
    </div>
    <div
      v-if="timings.length > 0"
      class="w-full items-center mt-2 justify-end flex"
      :class="
        ['R0', 'R1', 'R2'].includes(userRole) ? 'grid-cols-10' : 'grid-cols-9'
      "
    >
      <div class="flex w-1/12 mobile:col-span-3 justify-start mobile:w-full">
        <h4>Kokku:</h4>
        <span class="hidden mobile:flex mobile:ml-2">{{
          calculateTotalHours
        }}</span>
      </div>
      <div
        class="flex w-1/12 justify-start mobile:col-span-4 mobile:w-6/12 mobile:hidden"
      >
        {{ calculateTotalHours }}
      </div>
      <div class="flex w-1/12 mobile:hidden"></div>
      <!--      <div class="flex col-span-1 justify-center">{{ calculateTotalSum }}€</div>-->
    </div>
    <div v-if="timings.length === 0" class="mt-2">
      <span>Sellel perioodil puuduvad töötajal töötunnid</span>
    </div>
    <div v-if="showAddTimingForm">
      <add-worker-time
        :worker="worker"
        @closeModal="showAddTimingForm = false"
        @stopperSaved="saveNewStopper"
      ></add-worker-time>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import moment from "moment";
import DatePicker from "vuejs-datepicker";
import { ee } from "vuejs-datepicker/dist/locale";
import WorkerTime from "@/components/company/workers/WorkerTime";
import AddWorkerTime from "@/components/company/workers/AddWorkerTime";

export default {
  name: "WorkerTimes",
  props: {
    companyUuid: {
      type: String,
      default: ""
    },
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    },
    userRole: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      timings: [],
      timingsLoaded: false,
      startDate: null,
      endDate: null,
      ee: ee,
      showAddTimingForm: false,
      timesInited: false
    };
  },
  mixins: [RequestHandler],
  components: { AddWorkerTime, WorkerTime, DatePicker },
  methods: {
    routeToTask(e) {
      this.$emit("routeToTask", e);
    },
    loadWorkerStoppers() {
      this.timingsLoaded = false;
      let url =
        "tasks/" +
        this.$store.state.companyData.activeCompany.uuid +
        "/" +
        this.$route.params.id +
        "/tracking/";
      if (this.startDate !== null) {
        url = url + "?start=" + this.startTimeFormatted;
      }
      if (this.endDate !== null) {
        url =
          url +
          (this.startDate === null ? "?" : "&") +
          "end=" +
          this.endTimeFormatted;
      }
      this.timings = [];
      this.apiRequest(url, "get", true).then(res => {
        this.timings = res.data;
        this.timingsLoaded = true;
      });
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    saveNewStopper(e) {
      this.apiRequest(
        "tasks/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/stopper/create/",
        "post",
        true,
        e
      ).then(res => {
        if (res.status === 201) {
          this.showAddTimingForm = false;
          this.loadWorkerStoppers();
        }
      });
    },
    deleteItem(time_id) {
      this.apiRequest(
        "tasks/" +
          this.companyUuid +
          "/" +
          this.$route.params.id +
          "/" +
          time_id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          this.loadWorkerStoppers();
        }
      });
    },
    updateItem(data) {
      this.apiRequest(
        "tasks/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.$route.params.id +
          "/" +
          data.id +
          "/edit/",
        "patch",
        true,
        {
          start_time: this.combineDateAndTime(
            data.stopper_day,
            data.stopper_start
          ),
          end_time: this.combineDateAndTime(data.stopper_day, data.stopper_end)
        }
      ).then(res => {
        if (res.status === 200) {
          this.loadWorkerStoppers();
        }
      });
    },
    combineDateAndTime(date, new_time) {
      const time = moment(date).format("YYYY-MM-DD") + " " + new_time;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    }
  },
  computed: {
    calculateTotalHours() {
      let totalDuration = null;
      for (let i = 0; i < this.timings.length; i++) {
        if (i === 0) {
          totalDuration = moment.utc(
            moment
              .duration(
                moment(this.timings[i].stopper_end).diff(
                  moment(this.timings[i].stopper_start)
                )
              )
              .asMilliseconds()
          );
        } else {
          totalDuration.add(
            moment
              .duration(
                moment(this.timings[i].stopper_end).diff(
                  moment(this.timings[i].stopper_start)
                )
              )
              .asMilliseconds()
          );
        }
      }
      let time = moment(totalDuration);
      if (isNaN(time)) {
        return "00:00";
      } else {
        let timeHours =
          moment
            .duration(time, "milliseconds")
            .asHours()
            .toFixed(0)
            .toString().length === 1
            ? "0" +
              moment
                .duration(time, "milliseconds")
                .asHours()
                .toFixed(0)
            : moment
                .duration(time, "milliseconds")
                .asHours()
                .toFixed(0);
        let timeMinutes =
          time.minutes().toString().length === 1
            ? "0" + time.minutes()
            : time.minutes();
        return timeHours + ":" + timeMinutes;
      }
    },
    calculateTotalSum() {
      let sum = 0;
      for (let i = 0; i < this.timings.length; i++) {
        if (this.timings[i].stopper_end !== null) {
          sum +=
            (moment
              .duration(
                moment(this.timings[i].stopper_end).diff(
                  moment(this.timings[i].stopper_start)
                )
              )
              .asSeconds() /
              (60 * 60)) *
            5;
        }
      }
      return Math.round((sum + Number.EPSILON) * 100) / 100;
    },
    startTimeFormatted() {
      return moment(this.startDate).format("YYYY-MM-DD");
    },
    endTimeFormatted() {
      return moment(this.endDate).format("YYYY-MM-DD");
    }
  },
  watch: {
    startDate() {
      if (this.timesInited) {
        this.loadWorkerStoppers();
      }
    },
    endDate() {
      if (this.timesInited) {
        this.loadWorkerStoppers();
      }
    }
  },
  mounted() {
    this.startDate = moment()
      .clone()
      .startOf("month")
      .toDate();
    this.endDate = moment()
      .endOf("month")
      .toDate();
    this.loadWorkerStoppers();
    this.timesInited = true;
  }
};
</script>

<style lang="scss">
.worker-times-date-selector {
  div {
    input {
      @apply bg-offwhite shadow;
    }
  }
}
@media screen and (max-width: 992px) {
  .worker-times-date-selector {
    div {
      @apply w-full;
      input {
        @apply bg-offwhite shadow w-full;
      }
    }
  }
}
</style>
