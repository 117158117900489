var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workerLoaded)?_c('div',{staticClass:"flex flex-col shadow bg-white"},[(_vm.worker !== null)?_c('div',{staticClass:"flex flex-col rounded-gwdhalf"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-row w-10/12 mobile:w-full justify-start mobile:justify-between mb-4"},[(!_vm.editMode && ['R0', 'R1', 'R2'].includes(_vm.userRole))?_c('button',{staticClass:"new-button-danger mr-3",on:{"click":function($event){return _vm.closeDetailView(false)}}},[_c('span',{staticClass:"typcn-chevron-left typcn icon"}),_c('span',{staticClass:"label"},[_vm._v("Tagasi")])]):_vm._e(),(['R0', 'R1', 'R2'].includes(_vm.userRole) && !_vm.editMode)?_c('button',{class:_vm.editMode ? 'new-button-danger' : 'new-button-pending',on:{"click":function($event){_vm.$store.state.companyData.activeCompany.subscription_permissions
              .client_portal
              ? _vm.toggleEditMode()
              : _vm.$store.dispatch('userData/openSubWarning')}}},[_c('span',{staticClass:"typcn typcn-edit icon"}),(!_vm.editMode)?_c('span',{staticClass:"label"},[_vm._v("Muuda")]):_vm._e()]):_vm._e()]),(['R0', 'R1', 'R2'].includes(_vm.userRole) && _vm.editMode)?_c('button',{staticClass:"new-button-danger",on:{"click":function($event){_vm.isConfirmDeleteModalActive = true}}},[_vm._m(0),_c('span',{staticClass:"label"},[_vm._v("Kustuta töötaja")])]):_vm._e()]),_c('div',{staticClass:"flex flex-row flex-wrap"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex"},[_c('h2',[_vm._v(_vm._s(_vm.worker.profile_name))])]),_c('div',{staticClass:"flex flex-row mobile:flex-wrap"},[_c('div',{staticClass:"flex flex-col justify-center pr-2 w-1/3 mobile:w-full"},[_c('h3',[_vm._v("Kontaktinfo")]),_c('contact-info-card',{attrs:{"edit-mode":_vm.editMode,"worker":_vm.worker},on:{"workerUpdated":_vm.handleWorkerUpdate}})],1),(
              ['R0', 'R1', 'R2'].includes(
                _vm.$store.state.companyData.activeCompany.worker_permission
              )
            )?_c('div',{staticClass:"flex flex-col w-1/3 mobile:w-full px-2"},[_c('h3',[_vm._v("Isiklikud andmed")]),_c('div',{staticClass:"flex flex-col items-start py-1"},[_c('h4',{staticClass:"mr-2"},[_vm._v("Arvelduskonto nimi")]),(!_vm.editMode)?_c('span',[_vm._v(" "+_vm._s(_vm.worker.iban_account_name ? _vm.worker.iban_account_name : "-"))]):_vm._e(),(_vm.editMode)?_c('div',{staticClass:"w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editables.iban_account_name),expression:"editables.iban_account_name"}],staticClass:"bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full",class:_vm.$v.$error &&
                    _vm.editables.iban_code !== null &&
                    _vm.editables.iban_code !== '' &&
                    (_vm.editables.iban_account_name === null ||
                      _vm.editables.iban_account_name === '' ||
                      (!_vm.$v.editables.iban_account_name.$error &&
                        !_vm.$v.editables.iban_account_name.required))
                      ? 'shadow-dangeroutline'
                      : '',attrs:{"type":"text"},domProps:{"value":(_vm.editables.iban_account_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editables, "iban_account_name", $event.target.value)}}})]):_vm._e()]),_c('div',{staticClass:"flex flex-col items-start py-1"},[_c('h4',{staticClass:"mr-2"},[_vm._v("Arvelduskonto number")]),(!_vm.editMode)?_c('span',[_vm._v(" "+_vm._s(_vm.worker.iban_code ? _vm.worker.iban_code : "-"))]):_vm._e(),(_vm.editMode)?_c('div',{staticClass:"w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editables.iban_code),expression:"editables.iban_code"}],staticClass:"bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full",class:_vm.editables.iban_code.length > 0 &&
                    _vm.$v.editables.iban_code.$invalid
                      ? 'shadow-dangeroutline'
                      : '',attrs:{"type":"text"},domProps:{"value":(_vm.editables.iban_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editables, "iban_code", $event.target.value)}}})]):_vm._e()]),_c('div',{staticClass:"flex flex-col items-start py-1"},[_c('h4',{staticClass:"mr-2"},[_vm._v("Isikukood")]),(!_vm.editMode)?_c('span',[_vm._v(" "+_vm._s(_vm.worker.id_code ? _vm.worker.id_code : "-"))]):_vm._e(),(_vm.editMode)?_c('div',{staticClass:"w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editables.id_code),expression:"editables.id_code"}],staticClass:"bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.editables.id_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editables, "id_code", $event.target.value)}}})]):_vm._e()])]):_vm._e(),(!_vm.editMode)?_c('div',{staticClass:"flex w-1/3 justify-start mobile:w-full mb-8 p-2"},[(_vm.worker.profile !== null)?_c('div',{staticClass:"flex flex-col justify-start items-start mobile:items-start w-full"},[_vm._m(1),_c('div',{staticClass:"flex items-center bg-offwhite rounded-xl shadow mobile:w-full w-full"},[_c('div',{staticClass:"items-center justify-start flex w-3/12"},[_c('div',{staticClass:"p-2"},[_c('img',{staticClass:"h-12 w-12 rounded-full",attrs:{"src":_vm.workerImage}})])]),_c('span',{staticClass:"w-9/12"},[_vm._v(" "+_vm._s(_vm.worker.profile_name))])])]):_vm._e(),(
                _vm.worker.profile === null &&
                  ['R0', 'R1', 'R2'].includes(
                    _vm.$store.state.companyData.activeCompany.worker_permission
                  )
              )?_c('div',{staticClass:"flex flex-col items-center mobile:items-start"},[(_vm.worker.join_request_sent)?_c('div',{staticClass:"flex flex-col"},[_vm._m(2),(_vm.worker.join_request_email)?_c('span',{staticClass:"mb-2"},[_c('span',[_vm._v("Kutse saadetud aadressile "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.worker.join_request_email))]),_vm._v(".")])]):_vm._e(),(_vm.joinRequestResent)?_c('span',{staticClass:"mb-2"},[_vm._v(" Liitumise kutse edukalt uuesti saadetud! ")]):_vm._e(),_c('div',{staticClass:"flex flex-row"},[_c('button',{staticClass:"alt-button-pending mr-2",on:{"click":_vm.reSendJoinRequest}},[_c('span',{staticClass:"icon typcn typcn-plus"}),_c('span',{staticClass:"label"},[_vm._v("Saada uuesti")])]),_c('button',{staticClass:"alt-button-danger ml-2",on:{"click":_vm.deleteJoinRequest}},[_c('span',{staticClass:"icon typcn typcn-trash"}),_c('span',{staticClass:"label"},[_vm._v("Tühista liitumise kutse")])])])]):_vm._e(),(!_vm.worker.join_request_sent)?_c('div',{staticClass:"flex flex-col"},[_vm._m(3),(
                    _vm.$store.state.companyData.activeCompany
                      .subscription_permissions.client_portal
                  )?_c('span',{staticClass:"mb-2"},[_vm._v("Saada kasutajale Gewodo konto ühendamise taotlus")]):_vm._e(),(
                    !_vm.$store.state.companyData.activeCompany
                      .subscription_permissions.client_portal
                  )?_c('div',{staticClass:"mb-2 flex flex-col"},[_c('span',{staticClass:"mb-2"},[_vm._v("Et töötajatele kontosid luua, ostke Gewodo Premium pakett.")]),_c('router-link',{attrs:{"to":"/subscriptions/add"}},[_c('button',{staticClass:"new-button-green"},[_vm._v(" Uuenda pakett ")])])],1):_vm._e(),(
                    _vm.$store.state.companyData.activeCompany
                      .subscription_permissions.client_portal
                  )?_c('div',{staticClass:"flex flex-col items-start w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.joinEmail),expression:"joinEmail"}],staticClass:"add-project-input mb-2 w-full",attrs:{"type":"text","placeholder":"Kasutaja meiliaadress"},domProps:{"value":(_vm.joinEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.joinEmail=$event.target.value}}}),_c('button',{staticClass:"new-button-green",on:{"click":_vm.sendJoinRequest}},[_vm._m(4),_c('span',{staticClass:"label"},[_vm._v("Saada")])])]):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e()]),(_vm.editMode)?_c('div',{staticClass:"py-4 flex flex-col"},[(_vm.$v.$error)?_c('div',{staticClass:"error-box flex-col mb-4"},[(
                !_vm.$v.editables.id_code.maxLength ||
                  !_vm.$v.editables.id_code.minLength
              )?_c('span',[_vm._v("Isikukood peab koosnema 11-st numbrist")]):_vm._e(),(!_vm.$v.editables.id_code.numeric)?_c('span',[_vm._v("Isikukood tohib ainult numbreid sisaldada")]):_vm._e(),(!_vm.$v.editables.id_code.validateIdCodeControlNumber)?_c('span',[_vm._v("Isikukoodi kontrollnumber ei klapi")]):_vm._e(),(
                _vm.editables.iban_code !== null &&
                  _vm.editables.iban_code !== '' &&
                  !_vm.$v.editables.iban_code.ibanValidator
              )?_c('span',[_vm._v("Arvelduskonto number ei ole korrektne")]):_vm._e(),(
                _vm.editables.iban_code !== null &&
                  _vm.editables.iban_code !== '' &&
                  _vm.$v.editables.iban_account_name.$error &&
                  !_vm.$v.editables.iban_account_name.required
              )?_c('span',[_vm._v(" Palun sisestage arvelduskontoga seotud nimi ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex"},[_c('button',{staticClass:"new-button-green mr-3",on:{"click":_vm.saveChanges}},[_c('span',{staticClass:"icon typcn typcn-tick"}),_c('span',{staticClass:"label"},[_vm._v("Salvesta")])]),(['R0', 'R1', 'R2'].includes(_vm.userRole) && _vm.editMode)?_c('button',{staticClass:"new-button-danger",on:{"click":function($event){_vm.editMode = false}}},[_c('span',{staticClass:"typcn typcn-times icon"}),_c('span',{staticClass:"label"},[_vm._v("Tühista")])]):_vm._e()])]):_vm._e()])])]):_vm._e(),(
      _vm.$store.state.companyData.activeCompany.subscription_permissions
        .client_portal
    )?_c('div',{staticClass:"mt-4 w-full rounded-xl"},[_c('div',{staticClass:"tab-tray"},[_c('div',{staticClass:"rounded-l-xl tab-tray-item w-1/3",class:_vm.subPage === 1 ? 'tab-tray-item-active' : '',on:{"click":function($event){_vm.subPage = 1}}},[_vm._m(5),_c('span',{staticClass:"tab-tray-label"},[_vm._v(" Graafik ")])]),_c('div',{staticClass:"tab-tray-item w-1/3",class:_vm.subPage === 2 ? 'tab-tray-item-active' : '',on:{"click":function($event){_vm.subPage = 2}}},[_vm._m(6),_c('span',{staticClass:"tab-tray-label"},[_vm._v(" Töötunnid ")])]),_c('div',{staticClass:"tab-tray-item rounded-r-xl w-1/3",class:_vm.subPage === 3 ? 'tab-tray-item-active' : '',on:{"click":function($event){_vm.subPage = 3}}},[_vm._m(7),_c('span',{staticClass:"tab-tray-label"},[_vm._v(" Failid ")])])]),_c('div',{staticClass:"bg-white rounded-b-gwdhalf"},[(_vm.subPage === 1)?_c('worker-schedule',{attrs:{"worker":_vm.worker}}):_vm._e(),(_vm.subPage === 2)?_c('worker-times',{staticClass:"flex",attrs:{"worker":_vm.worker,"company-uuid":_vm.companyUuid,"user-role":_vm.userRole},on:{"routeToTask":_vm.routeToTask}}):_vm._e(),(_vm.subPage === 3)?_c('worker-files',{staticClass:"flex",attrs:{"worker":_vm.worker,"company-uuid":_vm.companyUuid,"user-role":_vm.userRole}}):_vm._e()],1)]):_vm._e(),(_vm.isConfirmDeleteModalActive)?_c('delete-confirm-modal',{attrs:{"text":"Olete kindel, et soovite valitud töötaja kustutada? Seda tegevust ei saa tagasi muuta"},on:{"closeModal":function($event){_vm.isConfirmDeleteModalActive = false},"confirmDelete":function($event){return _vm.deleteWorker()}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{staticClass:"h-4 filter-to-white",attrs:{"src":"/bc21/trash.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center w-full mb-2"},[_c('h3',{staticClass:"mr-2"},[_vm._v("Kasutaja ühendatud")]),_c('span',{staticClass:"w-6 h-6 rounded-full bg-green typcn typcn-tick flex justify-center items-center text-white text-xl leading-none shadow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mb-2"},[_vm._v(" Gewodo kasutaja loomise kutse saadetud!"),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center mb-2"},[_c('h3',{staticClass:"mr-3"},[_vm._v("Kasutaja ühendamata")]),_c('span',{staticClass:"w-4 h-4 bg-attention rounded-full"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('img',{staticClass:"h-4 w-4 filter-to-white",attrs:{"src":"/bc21/mail.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"tab-tray-icon"},[_c('img',{attrs:{"src":"/icons/worker/schedule.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"tab-tray-icon"},[_c('img',{attrs:{"src":"/icons/worker/hours.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"tab-tray-icon"},[_c('img',{attrs:{"src":"/bc21/dokumendid.svg"}})])}]

export { render, staticRenderFns }