<template>
  <worker-detail></worker-detail>
</template>

<script>
import WorkerDetail from "@/components/company/workers/WorkerDetail";
export default {
  components: {
    WorkerDetail
  }
};
</script>
