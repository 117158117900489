<template>
  <div class="w-full mt-2">
    <div class="flex flex-col" v-if="dataRetrieved">
      <div
        class="flex justify-start w-full text-darkgrey font-semibold px-5 py-2 rounded-full"
      >
        <span class="mr-3">{{
          moment(date).format("D") + "." + moment(date).format("MM")
        }}</span>
        <span>{{ getDayOfWeek() }}</span>
      </div>

      <div
        class="flex mb-3 flex-col w-full overflow-x-scroll flex-1 pb-2 "
        v-dragscroll
        v-dragscroll.x="true"
      >
        <div
          class="flex rounded-b-md overflow-y-scroll daily-cal-window"
          id="daily-cal-window"
        >
          <div class="flex flex-col text-darkgrey w-1/12">
            <div class="rounded-tl-xl daily-cal-time" id="0">
              00:00
            </div>
            <div class="daily-cal-time">
              01:00
            </div>
            <div class="daily-cal-time">
              02:00
            </div>
            <div class="daily-cal-time">
              03:00
            </div>
            <div class="daily-cal-time">
              04:00
            </div>
            <div class="daily-cal-time">
              05:00
            </div>
            <div class="daily-cal-time">
              06:00
            </div>
            <div class="daily-cal-time">
              07:00
            </div>
            <div class="daily-cal-time">
              08:00
            </div>
            <div class="daily-cal-time">
              09:00
            </div>
            <div class="daily-cal-time">
              10:00
            </div>
            <div class="daily-cal-time">
              11:00
            </div>
            <div class="daily-cal-time">
              12:00
            </div>
            <div class="daily-cal-time">
              13:00
            </div>
            <div class="daily-cal-time">
              14:00
            </div>
            <div class="daily-cal-time">
              15:00
            </div>
            <div class="daily-cal-time">
              16:00
            </div>
            <div class="daily-cal-time">
              17:00
            </div>
            <div class="daily-cal-time">
              18:00
            </div>
            <div class="daily-cal-time">
              19:00
            </div>
            <div class="daily-cal-time">
              20:00
            </div>
            <div class="daily-cal-time">
              21:00
            </div>
            <div class="daily-cal-time">22:00</div>
            <div class="rounded-bl-xl daily-cal-time">
              23:00
            </div>
          </div>
          <div class="flex w-full">
            <div
              class="w-auto flex flex-col relative px-2 items-center"
              :style="'width: 100%;'"
            >
              <task v-for="item in taskData" :key="item.id" :data="item"></task>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col px-2">
        <h4>Tänased ülesanded</h4>
        <span class="h-1 bg-green rounded-md mb-2"></span>
        <list-task
          v-for="item in taskData"
          :key="item.id"
          :data="item"
        ></list-task>
      </div>
    </div>
  </div>
</template>

<script>
const dayOfWeek = [
  { number: 1, name: "Esmaspäev" },
  { number: 2, name: "Teisipäev" },
  { number: 3, name: "Kolmapäev" },
  { number: 4, name: "Neljapäev" },
  { number: 5, name: "Reede" },
  { number: 6, name: "Laupäev" },
  { number: 0, name: "Pühapäev" }
];
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import Task from "./daily/Task";
import ListTask from "./daily/ListTask";
import { dragscroll } from "vue-dragscroll";

export default {
  props: {
    activeDate: {
      type: Object,
      default: () => {
        return moment();
      }
    },
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    Task,
    ListTask
  },
  mixins: [RequestHandler],
  directives: {
    dragscroll: dragscroll
  },
  data() {
    return {
      moment: moment,
      taskData: null,
      dataRetrieved: false,
      workers: [],
      date: null
    };
  },
  mounted() {
    if (!this.activeDate) {
      this.date = moment();
    } else {
      this.date = this.activeDate;
    }
    this.retrieveDayData(true);
  },
  methods: {
    getDayOfWeek() {
      return dayOfWeek.find(item => item.number === moment(this.date).day())
        .name;
    },
    retrieveDayData(full) {
      if (full) {
        this.dataRetrieved = false;
      }
      this.apiRequest(
        "calendar/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/tasks/" +
          this.worker.id +
          "/?period=day&start_time=" +
          this.date.format("YYYY-MM-DD"),
        "get",
        true
      ).then(res => {
        this.taskData = res.data;
        this.taskData = this.taskData.sort((a, b) => {
          if (moment(a.start_time).unix() > moment(b.start_time).unix()) {
            return 1;
          } else if (
            moment(a.start_time).unix() < moment(b.start_time).unix()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
        this.taskData = this.taskData.filter(
          x =>
            x.workers.find(y => y.profile === this.worker.profile) !== undefined
        );
        this.dataRetrieved = true;
      });
    }
  }
};
</script>
