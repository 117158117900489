<template>
  <div class="modal">
    <div class="modal-short">
      <div class="w-full flex flex-col">
        <div class="mb-3 flex flex-row justify-between items-center">
          <div class="flex flex-row">
            <span class="mr-3">Töötaja: </span>
            <span> {{ worker.profile_name }}</span>
          </div>
          <div>
            <button class="new-button-danger" @click="closeModal">
              <span class="typcn typcn-times icon"></span>
              <span class="label text-sm">Sulge</span>
            </button>
          </div>
        </div>
        <div class="flex mobile:flex-col">
          <div class="flex w-2/3 flex-col mobile:w-full mobile:mb-8">
            <div class="form-group mobile:w-full mobile:mr-0 mobile:mb-3">
              <h4>
                Kuupäev<span
                  title="Kohustuslik"
                  class="hover:text-dangerdark cursor-default duration-100"
                  >*</span
                >
              </h4>
              <div class="flex">
                <date-picker
                  class="add-task-date-selector w-full"
                  :class="[topCalendarShowing ? 'is-active' : '']"
                  :language="ee"
                  :format="formatDate"
                  :monday-first="true"
                  @opened="handleTopCalendarShow"
                  @closed="handleTopCalendarClose"
                  v-model="startDate"
                ></date-picker>
              </div>
            </div>
            <div class="flex flex-col mt-2">
              <worker-task-selector
                :selected-task="selectedTask"
                :worker="worker"
                :date="startDate"
                @selectTask="handleTaskSelection"
              >
              </worker-task-selector>
              <div v-if="selectedTask" class="mt-2">
                <h4 class="mb-2">
                  Valitud ülesanne
                </h4>
                <div
                  class="flex flex-row p-3 bg-offwhite rounded-gwdhalf items-center mobile:flex-col"
                >
                  <div
                    class="flex flex-row items-center justify-between mobile:w-full mobile:mb-2"
                    :class="
                      selectedTask.related_object ? 'w-6/12 mr-2' : 'w-full'
                    "
                  >
                    <span class="mr-2"> {{ selectedTask.title }} </span>
                    <span
                      class="ml-2"
                      v-html="parseTaskStatus(selectedTask.status)"
                    ></span>
                  </div>
                  <span
                    class="ml-2 mobile:w-full mobile:ml-0"
                    v-if="selectedTask.related_object"
                  >
                    Projekt: {{ selectedTask.related_object.object_name }}
                  </span>
                </div>
              </div>
              <div v-if="!selectedTask">
                <span>Töötundidega pole ülesannet ühendatud.</span>
              </div>
            </div>
          </div>
          <div class="flex w-1/3 flex-col mobile:w-full">
            <div
              class="flex mobile:justify-between mobile:w-full ml-3 mobile:ml-0 mobile:flex-col"
              v-if="timesInited"
            >
              <div
                class="flex flex-col w-1/2 mobile:items-center mobile:w-full mobile:flex-row mobile:mb-4 mobile:justify-between"
              >
                <h4>
                  Tööaja algus<span
                    title="Kohustuslik"
                    class="hover:text-dangerdark cursor-default duration-100"
                    >*</span
                  >
                </h4>
                <div class="flex pr-2 mobile:pr-0">
                  <time-selector
                    class="justify-end"
                    :value="startTime"
                    @input="handleStartTimeChange"
                    :is-white="false"
                    :full-minutes="true"
                  ></time-selector>
                </div>
              </div>
              <div
                class="flex flex-col w-1/2 mobile:items-center mobile:w-full mobile:flex-row mobile:justify-between"
              >
                <h4>
                  Tööaja lõpp<span
                    title="Kohustuslik"
                    class="hover:text-dangerdark cursor-default duration-100"
                    >*</span
                  >
                </h4>
                <div class="flex">
                  <time-selector
                    class="justify-end"
                    :value="endTime"
                    @input="handleEndTimeChange"
                    :is-white="false"
                    :full-minutes="true"
                  ></time-selector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-3 mobile:mt-8">
          <button class="new-button-green" @click="confirmPopup = true">
            <span class="typcn typcn-tick icon"></span>
            <span class="label text-sm">Salvesta</span>
          </button>
        </div>
        <confirm-modal
          v-if="confirmPopup"
          :text="'Kas Te olete kindel, et soovite salvestada?'"
          @confirm="saveWorkerTime"
          @closeModal="confirmPopup = false"
        ></confirm-modal>
      </div>
    </div>
  </div>
</template>

<script>
import TimeSelector from "@/components/reusable/TimeSelector";
import DatePicker from "vuejs-datepicker";
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import WorkerTaskSelector from "@/components/company/workers/WorkerTaskSelector";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import StatusParser from "@/assets/mixins/StatusParser";

export default {
  name: "WorkerTimeAdding",
  components: { ConfirmModal, WorkerTaskSelector, TimeSelector, DatePicker },
  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [StatusParser],
  data() {
    return {
      ee: ee,
      startTime: null,
      endTime: null,
      startDate: null,
      topCalendarShowing: false,
      timesInited: false,
      confirmPopup: false,
      selectedTask: null
    };
  },
  methods: {
    handleTaskSelection(e) {
      this.selectedTask = e;
    },
    handleStartTimeChange(e) {
      this.startTime = e;
    },
    handleEndTimeChange(e) {
      this.endTime = e;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    handleTopCalendarShow() {
      this.topCalendarShowing = true;
    },
    handleTopCalendarClose() {
      this.topCalendarShowing = false;
    },
    initTimes() {
      this.startDate = moment().toDate();
      this.startTime = moment()
        .add(1, "hours")
        .startOf("hour")
        .format("HH:mm");
      this.endTime = moment()
        .add(2, "hours")
        .startOf("hour")
        .format("HH:mm");
      this.timesInited = true;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    saveWorkerTime() {
      let data = {
        worker: this.worker.id,
        stopper_start: this.startTimeFormatted,
        stopper_end: this.endTimeFormatted
      };
      if (this.selectedTask) {
        data["task"] = this.selectedTask.id;
      }
      this.$emit("stopperSaved", data);
    }
  },
  computed: {
    startTimeFormatted() {
      const time =
        moment(this.startDate).format("YYYY-MM-DD") + " " + this.startTime;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    endTimeFormatted() {
      const time =
        moment(this.startDate).format("YYYY-MM-DD") + " " + this.endTime;
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    }
  },
  mounted() {
    this.initTimes();
  }
};
</script>
