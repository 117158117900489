<template>
  <div
    v-if="dataRetrieved"
    class="py-2 flex flex-col"
    id="worker-task-selector"
  >
    <div v-if="!date">
      <span>Palun valige aeg, et näha selle kuupäeva ülesandeid.</span>
    </div>
    <div
      v-if="taskData.length > 0"
      class="flex flex-wrap flex-col overflow-y-hidden overflow-x-scroll mobile:flex-col"
    >
      <div
        v-for="task in taskData"
        :key="task.id"
        class="p-3 cursor-pointer w-full border flex flex-row justify-between items-center rounded-gwdhalf mobile:w-full duration-100 mb-1"
        :class="
          selectedTask && task.id === selectedTask.id
            ? 'shadow  border-green'
            : 'border-transparent'
        "
        @click="selectTask(task)"
      >
        <h4 class="mr-2 truncate">{{ task.title }}</h4>
        <span class="ml-2" v-html="parseTaskStatus(task.status)"> </span>
      </div>
    </div>
    <div v-if="date && taskData.length === 0">
      <span>Sellel kuupäeval puuduvad töötajal ülesanded.</span>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import moment from "moment";
import StatusParser from "@/assets/mixins/StatusParser";

export default {
  name: "WorkerTaskSelector",
  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      }
    },
    date: Date,
    selectedTask: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      taskData: [],
      dataRetrieved: false
    };
  },
  mixins: [RequestHandler, StatusParser],
  methods: {
    retrieveTasks(full) {
      if (full) {
        this.dataRetrieved = false;
      }
      let startDate = null;
      if (this.date) {
        startDate = moment(this.date);
      }
      this.apiRequest(
        "calendar/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/tasks/" +
          this.worker.id +
          "/?period=day" +
          (startDate ? "&start_time=" + startDate.format("YYYY-MM-DD") : ""),
        "get",
        true
      ).then(res => {
        this.taskData = res.data;
        this.taskData = this.taskData.sort((a, b) => {
          if (moment(a.start_time).unix() > moment(b.start_time).unix()) {
            return 1;
          } else if (
            moment(a.start_time).unix() < moment(b.start_time).unix()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
        this.taskData = this.taskData.filter(
          x =>
            x.workers.find(y => y.profile === this.worker.profile) !== undefined
        );
        this.dataRetrieved = true;
      });
    },
    selectTask(e) {
      if (this.selectedTask && this.selectedTask.id === e.id) {
        this.$emit("selectTask", null);
      } else {
        this.$emit("selectTask", e);
      }
    }
  },
  mounted() {
    if (this.date) {
      this.retrieveTasks(true);
    }
  },
  watch: {
    date() {
      if (this.date) {
        this.retrieveTasks(true);
      }
    }
  }
};
</script>

<style lang="scss">
#worker-task-selector {
  max-height: 40vh;
  @apply overflow-y-scroll;
}
</style>
